import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'navbar',
    template: require('./navbar.html'),
    styles: [require('./navbar.scss')]
})
export class NavbarComponent {
    isCollapsed = true;
    menu = [];
    mainMenu = [{
        title: 'Home',
        link: '/home',
        active:'false'
    }];
    adminMenu = [{
        title: 'Cars',
        link: '/car',
        role: 'admin',
        active:'false'
    }, {
        title: 'Users',
        link: '/admin',
        role: 'admin',
        active:'false'
    }];
    loggedInMenu = [{
        title: 'Settings',
        link: '/settings',
        active:'false'
    }, {
        title: 'Logout',
        link: '/logout',
        active:'false'
    }];
    notLoggedInMenu = [{
        title: 'Inventory',
        link: '/inventory',
        active:'false'
    }, {
        title: 'About Us',
        link: '/about',
        active:'false'
    }, {
        title: 'Locations',
        link: '/location',
        active:'false'
    }];
    Router;
    isAdmin;
    isLoggedIn;
    currentUser = {};
    AuthService;
    static parameters = [AuthService, Router];
    constructor(authService: AuthService, router: Router) {
        this.AuthService = authService;
        this.Router = router;
        this.AuthService.currentUserChanged.subscribe(user => {
            if(user){
                this.currentUser = user;
            }
        });
    }

    ngOnDestroy() {
        this.AuthService.currentUserChanged.unsubscribe();
      }

    changeTab(item){
        // console.log(item);

        // this.mainMenu[0].active = 'true';

        for(let i=0; i<=this.mainMenu.length;i++){
            if(this.mainMenu[i] == item){
                this.mainMenu[i].active = 'true';
            } else {
                if(this.mainMenu[i]){
                this.mainMenu[i].active = 'false';
                }
            }
        }

        for(let i=0; i<=this.adminMenu.length;i++){
            if(this.adminMenu[i] == item){
                this.adminMenu[i].active = 'true';
            } else {
                if(this.adminMenu[i]){
                this.adminMenu[i].active = 'false';
                }
            }
        }

        for(let i=0; i<=this.loggedInMenu.length;i++){
            if(this.loggedInMenu[i] == item){
                this.loggedInMenu[i].active = 'true';
            } else {
                if(this.loggedInMenu[i]){
                this.loggedInMenu[i].active = 'false';
                }
            }
        }

        for(let i=0; i<=this.notLoggedInMenu.length;i++){
            if(this.notLoggedInMenu[i] == item){
                this.notLoggedInMenu[i].active = 'true';
            } else {
                if(this.notLoggedInMenu[i]){
                    this.notLoggedInMenu[i].active = 'false';
                }
            }
        }


        // item.active = 'true';

    }

    logout() {
        return this.AuthService.logout().then(() => {
            this.Router.navigateByUrl('/home');
        });
    }
}
