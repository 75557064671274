import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'feature',
    template: require('./feature.html'),
    styles: [require('./feature.scss')],
})
export class FeatureComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allImages = [];
    allFeatures = [];
    allFeatures = [];
    newFeature = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    featureImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";

    popUpObjectData = {
        roles:"test"
    }


    roleDropDownSettings = {};
    roleItemList = [];
    isStudent = false;

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        this.getFeatures();
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('features');
    }

    getAllRoles() {
        this.userService.getAllRoles().subscribe(res => {
            this.possibleRoles = res;
            this.roleItemList = [];
            this.possibleRoles.forEach((element, index) => {
                var tempItem = {
                    "id": element.id,
                    "itemName": element.type
                };
                this.roleItemList.push(tempItem);
            });
        })
    }

    onRoleSelect(item: any) {
        if (item.itemName === 'ROLE_STUDENT') {
            //   this.isStudent = true;
        }
        var tempRole = {
            "id": item.id,
            "type": item.itemName
        };
        // this.userToAdd.roles.push(tempRole);
    }

    onRoleDeSelect(item: any) {
        if (item.itemName === 'ROLE_STUDENT') {
            this.isStudent = false;
        }
        // this.userToAdd.roles.splice(this.userToAdd.roles.indexOf(item.id), 1);
    }


    onDeSelectAllRole(item: any) {
        this.isStudent = false;
    }


    onSelectAllRole(item: any) {
        this.isStudent = true;
    }
    setUpMultiSelectSettings() {
        this.roleDropDownSettings = {
            text: "Select Role(s)",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes: "myclass custom-class"
        };
    }
    getFeatures() {
        return this.http.get('/api/features').subscribe((features) => {
            this.allFeatures = features;
            this.SocketService.syncUpdates('features', this.allFeatures);
        });
    }

    deleteFeature(feature) {
        return this.http.delete(`/api/features/${feature._id}`)
            .subscribe(() => {
            });
    }

    confirmDelete(feature) {
        this.messageService('Are you sure you want to delete this feature?', 'Are you sure?', 'delete', feature);
    }


    clearout() {
        this.newFeature = {};
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteFeature(item);
                    this.messageService('The feature has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
