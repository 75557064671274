import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';
type Thing = {
    name: string;
    info?: string;
};


@Component({
    selector: 'about',
    template: require('./about.html'),
    styles: [require('./about.scss')],
})
export class AboutComponent implements OnInit, OnDestroy {
    SocketService;
    awesomeThings: Thing[] = [];
    newThing = '';

    monsterLife = 100;
    monsterMaxLife = 100;
    damageMin = 10;
    damageMax = 100;
    energy = 5;

    maxCritical = this.damageMax * 2;
    minCritical = this.damageMin * 2;
    critChance = 2;
    skillsPicked = 0;

    xp = 0;
    criticalHit = '';
    playerLevel = 1;

    life = 100;

    damage = '';

    level = 1;

    items = [];

    displayChooseSkill = false;
    skillTree: String[] = [];
    skillTree = [
        'Plus 10 to energy',
        'Plus 10 to min damage',
        'Plus 10 to max damage',
        'Plus 10 to max critical damage',
        'Plus 10 to min critical damage',
        'Plus 1 to critical percent'];


    monsters = [
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png',
        'https://images.saymedia-content.com/.image/t_share/MTc0MzA1MDU1NDM5MDcxMTAw/top-50-coolest-enemies-and-monsters-in-video-games.jpg',
        'https://www.gmbinder.com/images/4xBV4kG.png',
        'https://www.gmbinder.com/images/cfCly0q.png',
        'https://wow.zamimg.com/modelviewer/live/webthumbs/npc/174/98478.png'
    ]



    static parameters = [HttpClient, SocketService];
    constructor(http: HttpClient, socketService: SocketService) {
        this.http = http;
        this.SocketService = socketService;
    }

    ngOnInit() {
        // return this.http.get('/api/things')
        //     .subscribe((things: Thing[]) => {
        //         this.awesomeThings = things;
        //         this.SocketService.syncUpdates('thing', this.awesomeThings);
        //     });
    }


    ngOnDestroy() {
        // this.SocketService.unsyncUpdates('thing');
    }

    hit() {


        var max = 3;
        var min = 1;
        var attack = Math.floor(Math.random() * (max - min + 1)) + min;
        this.life = this.life - attack;

        if (this.energy == 0) {
            alert('You Are Out Of Energy!');
        } else {
            this.energy = this.energy - 1;
            var crit = Math.floor((Math.random() * 100) + this.critChance);

            var max = 100;
            var min = 1;
            var crit = Math.floor(Math.random() * (max - min + 1)) + min;
            if (crit <= this.critChance) {
                this.criticalHitText = 'CRITICAL HIT!!';
                this.damage = Math.floor((Math.random() * (this.maxCritical - this.minCritical + 1)) + this.minCritical);
                console.log(this.damage);
            } else {
                this.criticalHitText = '';
                this.damage = Math.floor((Math.random() * (this.damageMax - this.damageMin + 1)) + this.damageMin);

            }
            this.monsterLife = this.monsterLife - this.damage;
            if (this.monsterLife <= 0) {
                //Monster is dead
                this.xp = this.xp + 1;
                if (this.xp == 3) {
                    this.levelUp();
                    this.playerLevel = this.playerLevel + 1;
                    this.xp = 0;
                }
                this.level++;
                this.monsterLife = (this.level + 1) * 50;
                this.monsterMaxLife = this.monsterLife;
                this.getChest();
            }
        }
    }

    levelUp() {
        this.displayChooseSkill = true;
    }

    lifeBar(life) {
        return life;
    }

    getChest() {
        var numberOfItems = Math.floor((Math.random() * (6 - 1 + 1)) + 1);
        var max = 100;
        var min = 1;
        var numberOfItems = Math.floor(Math.random() * (max - min + 1)) + min;
        for (let i = 0; i <= numberOfItems - 1; i++) {
            this.items.push(this.getItem());
        }
    }

    inventory() {
        this.items.forEach(element => {
            console.log(element);
        });
    }

    getItem() {
        var range = this.pickRange();
        var numberOfAttributes = 0;
        if (range == 'low') {
            numberOfAttributes = 1;
        }
        if (range == 'mid') {
            numberOfAttributes = 2;
        }
        if (range == 'high') {
            numberOfAttributes = 3;
        }

        var singleItem = [];
        for (let i = 0; i <= numberOfAttributes - 1; i++) {
            singleItem.push(this.getAtr());
        }
        return singleItem;
    };

    getAtr() {
        var range = this.pickRange();
        var max;
        var min;
        if (range == 'low') {
            max = 3;
            min = 1;
        }
        if (range == 'mid') {
            max = (10 + this.level / 2);
            min = 3;
        }
        if (range == 'high') {
            max = (50 + this.level / 2);
            min = 30;
        }
        var total = Math.floor(Math.random() * (max - min + 1)) + min;
        return 'Plus ' + total + ' to ' + this.getSkill();
    }

    getSkill() {
        var range = this.pickRange();
        var max = 3;
        var min = 1;
        var oneOrTwo = Math.floor(Math.random() * (max - min + 1)) + min;
        if (range == 'low') {
            if (oneOrTwo == 1) {
                return 'energy';
            } else {
                return 'life';
            }
        }
        if (range == 'mid') {
            if (oneOrTwo == 1) {
                return 'min damage';
            } else if (oneOrTwo == 2) {
                return 'max damage';
            } else {
                if(this.critChance >= 100){
                    return 'max damage'
                } else {
                    return 'critical percent';
                }
            }
        }
        if (range == 'high') {
            if (oneOrTwo == 1) {
                return 'min critical damage';
            } else {
                return 'max critical damage';
            }
        }
        return 'oops';
    }

    //Returns low mid high;
    pickRange() {
        var max = 10;
        var min = 1;
        var oneOutOfTen = Math.floor(Math.random() * (max - min + 1)) + min;
        if (oneOutOfTen == 1) {
            var newOneOutOfTen = Math.floor(Math.random() * (max - min + 1)) + min;
            if (newOneOutOfTen == 1) {
                return 'high';
            } else {
                return 'mid';
            }
        }
        return 'low';
    }

    usAll(){
        this.items.forEach(element => {
            element.forEach(element => {
                console.log(element);
                this.useSkill(element);
            });
        })

        this.items = [];
    }

    useSkill(skill) {
        this.skillsPicked = this.skillsPicked + 1;
        var item = [];
        item.push(skill);
        this.useItem(item, 'skill');
        if (this.skillsPicked == 1) {
            this.displayChooseSkill = false;
            this.skillsPicked = 0;
        }
        
    }

    useItem(item, index) {
        for (let i = 0; i <= item.length - 1; i++) {
            const myArray = item[i].split(" ");
            if (myArray[3] == 'life') {
                this.life = this.life + Number(myArray[1]);
                // this[myArray[3]] =  this[myArray[3]]  + Number(myArray[1]);
            }
            if (myArray[3] == 'energy') {
                this.energy = this.energy + Number(myArray[1]);
            }
            if (myArray[3] == 'max') {
                if (myArray[4] == 'damage') {
                    this.damageMax = this.damageMax + Number(myArray[1]);
                    this.maxCritical = this.maxCritical + (Number(myArray[1]) * 2);
                }
                if (myArray[4] == 'critical') {
                    this.maxCritical = this.maxCritical + Number(myArray[1]);
                }
            }
            if (myArray[3] == 'min') {
                if (myArray[4] == 'damage') {
                    this.damageMin = this.damageMin + Number(myArray[1]);
                    this.minCritical = this.minCritical + (Number(myArray[1]) * 2);
                    if (this.damageMin >= this.damageMax) {
                        this.damageMax = this.damageMin;
                        this.maxCritical = this.maxCritical + (Number(myArray[1]) * 2);
                    }
                }
                if (myArray[4] == 'critical') {
                    this.minCritical = this.minCritical + Number(myArray[1]);
                    if (this.minCritical >= this.maxCritical) {
                        this.maxCritical = this.minCritical;
                    }
                }
            }
            if (myArray[3] == 'critical') {
                this.critChance = this.critChance + Number(myArray[1]);
            }
            if (myArray[3] == 'energy') {
                this.energy = this.energy + Number(myArray[1]);
            }
        }
        if (index == 'skill') {
        } else {
            this.items.splice(index, 1);
        }
    }

}
