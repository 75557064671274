import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { SocketService } from '../../socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'uploadfiles',
    template: require('./uploadfile.html'),
    styles: [require('./uploadfile.scss')],
})
export class UploadFileComponent implements OnInit, OnDestroy {
    SocketService;
    allUploadFiles = [];
    newUploadFile = '';
    uploadfilesFormData = [];
    uploadfileUploadProgress = [];
    removeIndex = [];
    uploadfileHolder = "";
    AuthService;
    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        // console.log(this.AuthService.getCurrentUserSync());
        // return this.http.get('/api/images').subscribe((uploadfiles) => {
        //     uploadfiles.forEach(uploadfile => {
        //         uploadfile.path = this.SocketService.website + uploadfile.path;
        //     });
        //     this.allUploadFiles = uploadfiles;
        //     this.SocketService.syncUpdates('uploadfile', this.allUploadFiles);
        // });
        if (this.AuthService.getCurrentUserSync()) {
            return this.http.get('/api/images/owner/' + this.AuthService.getCurrentUserSync()._id).subscribe((uploadfiles) => {
                uploadfiles.forEach(uploadfile => {
                    uploadfile.path = this.SocketService.website + uploadfile.path;
                });
                this.allUploadFiles = uploadfiles;
                this.SocketService.syncUpdates('uploadfile', this.allUploadFiles);
            });
        }
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('uploadfile');
    }

    addUploadFile() {
        if(this.newUploadFile) {
            let text = this.newUploadFile;
            this.newUploadFile = '';

            return this.http.post('/api/images', { name: text }).subscribe(uploadfile => {
                console.log('Added UploadFile:', uploadfile);
            });
        }
    }

    confirmDelete(uploadfile) {
        this.messageService('Are you sure you want to delete this uploadfile?', 'Are you sure?', 'delete', uploadfile);
    }


    deleteUploadFileFile(uploadfile) {
        console.log(uploadfile);
        return this.http.delete(`/api/images/${uploadfile._id}`).subscribe(() => {
            console.log('Deleted UploadFile');
        }, error => {
            console.log(error);
        });
    }


    displayFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;
        if (Math. abs(bytes) < thresh) {
          return bytes + ' B';
        }
        const units = si 
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;
        do {
          bytes /= thresh;
          ++u;
        } while (Math. round(Math. abs(bytes) * r) / r >= thresh && u < units. length - 1);
        return bytes. toFixed(dp) + ' ' + units[u];
      }

    uploadMultiple(event: any) {
        const files: FileList = event.target.files;
        const startIndex = this.uploadfileUploadProgress.length;
        for (let index = (0 + startIndex); index < files.length + startIndex; index++) {
            const fileIndex = index - startIndex;
            const fileData = new FormData();
            this.uploadfileUploadProgress[index] = {
                currentUploadProgressValue: 0,
                fileName: "File Name: " + files[fileIndex].name + " File Size: " + this.displayFileSize(files[fileIndex].size),
                currentUplaodSuccessMessage: '',
                currentUplaodErrorMessage: '',
                currentUploadFileId: '',
                currentUploadFileUrl: ''
            };
            const oldName = files[fileIndex].name
            const newName = Date.now();
            const extension = files[fileIndex].name.substring(files[fileIndex].name.lastIndexOf('.'), files[fileIndex].name.length);
            const currentUploadFile = {
                oldName: oldName,
                newName: newName,
                extension: extension,
                path: '/assets/files/' + newName,
                location: './client/assets/files/' + newName,
                size: files[fileIndex].size,
                type: files[fileIndex].type,
                owner: this.AuthService.getCurrentUserSync()._id + this.AuthService.getCurrentUserSync().role + '-uploadfilespage-addUploadFile',
                ownerid: this.AuthService.getCurrentUserSync()._id
            };
            fileData.append('body', JSON.stringify(currentUploadFile));
            fileData.append('file', files[fileIndex], newName);
            this.http.post('/api/images/', fileData, {reportProgress: true, observe: 'events', responseType: 'json'}).subscribe(response => {
                if (response.type === 3) {
                    this.uploadfileUploadProgress[index].currentUplaodSuccessMessage = "Upload Complete.";
                }
                if (response.type === 1) {
                    const percentDone = Math.round(100 * response.loaded / response.total);
                    this.uploadfileUploadProgress[index].currentUploadProgressValue = percentDone;
                }
                if (response.type === 4) {
                    this.uploadfilesFormData.push(response.body);
                    this.uploadfileUploadProgress[index].currentUploadFileId = response.body._id;
                    this.uploadfileUploadProgress[index].currentUploadFileUrl = response.body.path;
                }
                if (response && response.body) {
                    this.removeIndex.push(this.uploadfileUploadProgress[index]);
                    response.body.sort = this.SocketService.fileUploader.length;
                    this.SocketService.fileUploader.push(response.body);
                    this.checkDone();
                }
            }, err => {
              this.uploadfileUploadProgress[index].currentUplaodErrorMessage = err.statusText;
            });
        }
    }

    checkDone() {
        if (this.removeIndex.length==this.uploadfileUploadProgress.length) {
            this.uploadfileUploadProgress = [];
            this.removeIndex = [];
        }
    }

    deleteUploadFile(uploadfileStatus) {
        if (uploadfileStatus.currentUploadFileId) {
            this.uploadfileHolder = "";
            const removeIndex1 = this.uploadfilesFormData.map((item1) => item1._id).indexOf(uploadfileStatus.currentUploadFileId);
            if (removeIndex1 !== -1) {
                this.uploadfilesFormData.splice(removeIndex1, 1);
                this.SocketService.fileUploader = this.uploadfilesFormData;
            }
            const removeIndex2 = this.uploadfileUploadProgress.map((item2) => item2.currentUploadFileId).indexOf(uploadfileStatus.currentUploadFileId);
            if (removeIndex2 !== -1) {
                this.uploadfileUploadProgress.splice(removeIndex2, 1);
            }
            // return this.http.delete(`/api/images/${uploadfileStatus.currentUploadFileId}`).subscribe(() => {
                
            // });
        }
    }



    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteUploadFileFile(item);
                    this.messageService('The uploadfile has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
