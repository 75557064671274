import {
    NgModule,
    ApplicationRef,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    removeNgStyles,
    createNewHosts,
    createInputTransfer,
} from '@angularclass/hmr';

import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { DirectivesModule } from '../components/directives.module';
import { JwtModule } from '@auth0/angular-jwt';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
import { ImageModule } from './admin/image/image.module';
import { InventoryModule } from './inventory/inventory.module';
import { AboutModule } from './about/about.module';
import { LocationModule } from './location/location.module';
import { CarModule } from './admin/car/car.module';
import { VehicleModule } from './vehicle/vehicle.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FeatureModule } from './admin/feature/feature.module';

export function tokenGetter() {
    return localStorage.getItem('id_token');
}

const appRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full'}
    // path: '**', component: VehicleComponent
];

// const appRoutes: Routes = [ {
//     path: 'vehicle/:id',
//     component: VehicleComponent,
//     children: [
//       {
//         redirectTo: '/home',
//         pathMatch: 'full'
//       }
//     ]
//   }];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
            }
        }),

        // RouterModule.forRoot(appRoutes, { enableTracing: process.env.NODE_ENV === 'development' }),
        RouterModule.forRoot(appRoutes, { enableTracing: false }),
        MainModule,
        InventoryModule,
        AboutModule,
        LocationModule,
        DirectivesModule,
        AccountModule,
        AdminModule,
        ImageModule,
        CarModule,
        VehicleModule,
        FeatureModule,
        IvyCarouselModule,
        AngularMultiSelectModule
    ],
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    static parameters = [ApplicationRef];
    constructor(appRef: ApplicationRef) {
        this.appRef = appRef;
    }

    hmrOnInit(store) {
        if(!store || !store.state) return;
        console.log('HMR store', store);
        console.log('store.state.data:', store.state.data);
        // inject AppStore here and update it
        // this.AppStore.update(store.state)
        if('restoreInputValues' in store) {
            store.restoreInputValues();
        }
        // change detection
        this.appRef.tick();
        Reflect.deleteProperty(store, 'state');
        Reflect.deleteProperty(store, 'restoreInputValues');
    }

    hmrOnDestroy(store) {
        var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        // var appState = this.AppStore.get()
        store.state = {data: 'yolo'};
        // store.state = Object.assign({}, appState)
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    }

    hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        Reflect.deleteProperty(store, 'disposeOldHosts');
        // anything you need done the component is removed
    }
}
