import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'image',
    template: require('./image.html'),
    styles: [require('./image.scss')],
})
export class ImageComponent implements OnInit, OnDestroy {
    SocketService;
    allImages = [];
    newImage = '';
    imagesFormData = [];
    imageUploadProgress = [];
    imageHolder = "";
    defaultImage = '../../../assets/images/defaultimage.gif';
    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
    }

    ngOnInit() {
        return this.http.get('/api/images')
            .subscribe((images) => {
                images.forEach(image => {
                    image.path = this.SocketService.website + image.path;
                });
                this.allImages = images;
                this.SocketService.syncUpdates('image', this.allImages);

            });
    }


    ngOnDestroy() {
        this.SocketService.unsyncUpdates('image');
    }

    addImage() {
        if(this.newImage) {
            let text = this.newImage;
            this.newImage = '';

            return this.http.post('/api/images', { name: text })
                .subscribe(image => {
                    console.log('Added Image:', image);
                });
        }
    }

    confirmDelete(image) {
        this.messageService('Are you sure you want to delete this image?', 'Are you sure?', 'delete', image);
    }


    deleteImageFile(image) {
        console.log(image);
        return this.http.delete(`/api/images/${image._id}`)
            .subscribe(() => {
                console.log('Deleted Image');
            }, error => {
                console.log(error);
            });
    }


    displayFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;
        if (Math. abs(bytes) < thresh) {
          return bytes + ' B';
        }
        const units = si 
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;
        do {
          bytes /= thresh;
          ++u;
        } while (Math. round(Math. abs(bytes) * r) / r >= thresh && u < units. length - 1);
        return bytes. toFixed(dp) + ' ' + units[u];
      }

    uploadMultiple(event: any) {
        const files: FileList = event.target.files;
        const startIndex = this.imageUploadProgress.length;
        for (let index = (0 + startIndex); index < files.length + startIndex; index++) {
            const fileIndex = index - startIndex;
            const fileData = new FormData();
            this.imageUploadProgress[index] = {
                currentUploadProgressValue: 0,
                fileName: "File Name: " + files[fileIndex].name + " File Size: " + this.displayFileSize(files[fileIndex].size),
                currentUplaodSuccessMessage: '',
                currentUplaodErrorMessage: '',
                currentImageId: '',
                currentImageUrl: ''
            };
            const oldName = files[fileIndex].name
            const newName = Date.now();
            const extension = files[fileIndex].name.substring(files[fileIndex].name.lastIndexOf('.'), files[fileIndex].name.length);
            const currentImage = {
                oldName: oldName,
                newName: newName,
                extension: extension,
                path: '/assets/files/' + newName,
                location: './client/assets/files/' + newName,
                size: files[fileIndex].size,
                type: files[fileIndex].type,
                owner: this.AuthService.getCurrentUserSync()._id + this.AuthService.getCurrentUserSync().role + '-imagespage-addImage'
            };
            fileData.append('body', JSON.stringify(currentImage));
            fileData.append('file', files[fileIndex], newName);
            this.http.post('/api/images/', fileData, {reportProgress: true, observe: 'events', responseType: 'json'}).subscribe(response => {
                if (response.type === 3) {
                    this.imageUploadProgress[index].currentUplaodSuccessMessage = "Upload Complete.";
                }
                if (response.type === 1) {
                    const percentDone = Math.round(100 * response.loaded / response.total);
                    this.imageUploadProgress[index].currentUploadProgressValue = percentDone;
                }
                if (response.type === 4) {
                    this.imagesFormData.push(response.body);
                    this.imageUploadProgress[index].currentImageId = response.body._id;
                    this.imageUploadProgress[index].currentImageUrl = response.body.path;
                }
            }, err => {
              this.imageUploadProgress[index].currentUplaodErrorMessage = err.statusText;
            });
        }
    }

    deleteImage(imageStatus) {
        if (imageStatus.currentImageId) {
            return this.http.delete(`/api/images/${imageStatus.currentImageId}`).subscribe(() => {
                const removeIndex1 = this.imagesFormData.map((item1) => item1._id).indexOf(imageStatus.currentImageId);
                if (removeIndex1 !== -1) {
                    this.imagesFormData.splice(removeIndex1, 1);
                    console.log(this.imagesFormData);
                }
                const removeIndex2 = this.imageUploadProgress.map((item2) => item2.currentImageId).indexOf(imageStatus.currentImageId);
                if (removeIndex2 !== -1) {
                    this.imageUploadProgress.splice(removeIndex2, 1);
                    console.log(this.imageUploadProgress);
                }
            });
        }
    }



    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteImageFile(item);
                    this.messageService('The image has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
