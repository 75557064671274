import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'main',
    template: require('./main.html'),
    styles: [require('./main.scss')],
})
export class MainComponent implements OnInit, OnDestroy {
    SocketService;
    allCars = [];
    Router;
    defaultImage = '../../assets/images/defaultimage.gif';

    static parameters = [HttpClient, SocketService, Router, ActivatedRoute];
    constructor(http: HttpClient, socketService: SocketService, router: Router, Route: ActivatedRoute) {
        this.http = http;
        this.SocketService = socketService;
        this.Router = router;
        this.Route = Route;
        this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.getCars();
    }

    getCars(){
        return this.http.get('/api/cars').subscribe((cars) => {
            var hiddenCars = [];
            cars.forEach(car => {
                car.images.forEach(image => {
                    if (image) {
                        if (image.path) {
                            image.path = this.SocketService.website + image.path;
                        }
                    }
                });
                if (car.hidden=='true') {
                    hiddenCars.push(car);
                } 
            });
            hiddenCars.forEach(carElement => {
                const locationIndex = cars.map((item) => item._id).indexOf(carElement._id);
                if (locationIndex != -1) {
                    cars.splice(locationIndex, 1);
                } 
            });
            this.allCars = cars;
            this.SocketService.syncUpdates('cars', this.allCars);
        });
    }

    readMore(car){
        this.Router.navigateByUrl('/vehicle?car=' + car._id);
    }

}
