import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { SocketService } from '../../../components/socket/socket.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'car',
    template: require('./car.html'),
    styles: [require('./car.scss')],
})
export class CarComponent implements OnInit, OnDestroy {
    http;
    SocketService;
    AuthService;
    allCars = [];
    allFeatures = [];
    newCar = {};
    pageSettings = {
        loading: false,
        modifying: false,
        currentObject: {}
    };
    defaultImage = '../../../assets/images/defaultimage.gif';
    carImagesFormData = [];
    imageUploadProgress = [];
    displayImages = false;
    imagetype = "";
    addFeatureWindow = false;
    popUpObjectData = {
        roles:"test"
    }
    featureData = {
        id: '',
        name: ''
    }
    myClass: any;
    selectedItems = [];

    bodies = [
    "Coupe",
    "Sedan",
    "Hatchback",
    "Convertible",
    "Ex Cab",
    "Reg Cab",
    "Quad Cab",
    "Wagon",
    "Travel Trailer",
    "Utility Trailer",
    "Minivan",
    "Passenger Van",
    "Cargo Van",
    "SUV",
    "Motorcycle"];

    transmisshions = [
    "Auto Manual",
    "Automatic",
    "Manual",
    "CVT"];

    engines = [
    "1 Cylinder",
    "3 Cylinder",
    "4 Cylinder",
    "5 Cylinder",
    "6 Cylinder",
    "7 Cylinder",
    "8 Cylinder",
    "10 Cylinder",
    "12 Cylinder"];

    conditions = [
    "Excellent",
    "Very Good",
    "Good",
    "Fair",
    "Poor"];

    titles = [
    "Clean",
    "Rebuilt",
    "Salvage"];

    interiorTypes = [
    "Cloth",
    "Leather",
    "Vinyl",
    "Cloth & Leather"];

    drivetrains = [
    "FWD",
    "RWD",
    "AWD",
    "4X4"];
        

    roleDropDownSettings = {};
    featureList = [];
    featureItemList = [];
    isStudent = false;

    static parameters = [HttpClient, SocketService, AuthService];
    constructor(http: HttpClient, socketService: SocketService, authService: AuthService) {
        this.http = http;
        this.SocketService = socketService;
        this.AuthService = authService;
        
    }

    ngOnInit() {
        this.getCars();
        this.getFeatures();
        this.setUpMultiSelectSettings();
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('cars');
    }

    onFeatureSelect(item: any) {
        if(this.newCar.features){
            this.newCar.features = this.newCar.features +", "+item.itemName;
        } else {
            this.newCar.features = item.itemName;
        }
    }

    setUpMultiSelectSettings() {
        this.roleDropDownSettings = {
            text: "Select Feature(s)",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        };
    }

    getCars() {
        return this.http.get('/api/cars').subscribe((cars) => {
            cars.forEach(car => {
                car.images.forEach((image, index) => {
                    if (image) {
                        if (image.path) {
                            image.path = this.SocketService.website + image.path;
                            image.sort = index;
                        }
                        if (!image.sort) {
                            image.sort = index;
                        }
                    }
                });
            });
            this.allCars = cars;
            this.SocketService.syncUpdates('cars', this.allCars);
        });
    }

    selectCar(car) {
        this.SocketService.fileUploader = car.images;
        this.newCar = car;
    }

    addCarWindow(car){
        this.newCar.displacement = "Liters";
        this.SocketService.fileUploader = car.images;
        this.newCar = car;
    }

    showAddFeature(){
        this.addFeatureWindow = true;
    }

    closeAddFeature(){
        this.addFeatureWindow = false;
    }

    saveNewFeature(){
        return this.http.post('/api/features/', this.featureData).subscribe(feature => {
            this.featureClearout();
            this.messageService('Feature has been Added.', 'Success!', 'success');
            this.getFeatures();
            this.addFeatureWindow = false;
        });
    }

    getFeatures() {
        return this.http.get('/api/features').subscribe((value) => {
            this.possibleFeatures= value;
            this.featureItemList = [];
            this.possibleFeatures.forEach((element, index) => {
              var tempItem = {
                "id": element._id,
                "itemName": element.name
              };
              this.featureItemList.push(tempItem);
            });
            this.possibleFeatures =  _.sortBy(this.possibleFeatures,"name");
            this.allFeatures = value;
            this.SocketService.syncUpdates('features', this.allFeatures);
        });
    }

    sort(image, type) {
        const locationIndex = this.newCar.images.map((item) => item._id).indexOf(image._id);
        if (!image.sort && locationIndex>=this.newCar.images.length-1) {
            image.sort = this.newCar.images.length-1;
        }
        if (!image.sort) {
            image.sort = 0;
        }
        if (type=='more') {
            if ((this.newCar.images.length - 1)>image.sort) {
                if (locationIndex !== -1) {
                    var temp = this.newCar.images[locationIndex];
                    this.newCar.images[locationIndex + 1].sort = +this.newCar.images[locationIndex + 1].sort - +1;
                    this.newCar.images[locationIndex] = this.newCar.images[locationIndex + 1]
                    this.newCar.images[locationIndex + 1] = temp;
                }
                image.sort = +image.sort + +1;
            }
        }
        if (type=='less') {
            if (image.sort>0) {
                if (locationIndex !== -1) {
                    var temp = this.newCar.images[locationIndex];
                    this.newCar.images[locationIndex - 1].sort = +this.newCar.images[locationIndex - 1].sort + +1;
                    this.newCar.images[locationIndex] = this.newCar.images[locationIndex - 1]
                    this.newCar.images[locationIndex - 1] = temp;
                }
                image.sort = +image.sort - +1;
            }
        }
    }

    addCar() {
        if (this.newCar._id) {
            return this.http.put('/api/cars/' + this.newCar._id, this.newCar).subscribe(car => {
                this.clearout();
                this.messageService('Car has been modified.', 'Success!', 'success');
                this.getCars();
            });
        } else {
            return this.http.post('/api/cars', this.newCar).subscribe(car => {
                const locationIndex = this.allCars.map((item) => item._id).indexOf(car._id);
                if (locationIndex !== -1) {
                    this.allCars[locationIndex].image = this.newCar.image;
                }
                this.clearout();
                this.messageService('Car has been added.', 'Success!', 'success');
                this.getCars();
            });
        }
    }

    hideCar(car, state) {
        car.hidden = state;
        return this.http.put('/api/cars/' + car._id, car).subscribe(car => {
            this.clearout();
            this.messageService('Car has been modified.', 'Success!', 'success');
            this.getCars();
        });
    }

    deleteImage(image) {
        const newLocationIndex = this.newCar.images.map((item) => item._id).indexOf(image._id);
        if (newLocationIndex !== -1) {
            this.newCar.images[newLocationIndex] = null;
            this.newCar.images.splice(newLocationIndex, 1);
            // return this.http.put('/api/cars/' + this.newCar._id, this.newCar).subscribe(car => {
            //     const locationIndex = this.allCars.map((item) => item._id).indexOf(car._id);
            //     // this.clearout();
            //     // this.messageService('Car image has been deleted.', 'Success!', 'success');
            //     // this.getCars();
            // });
        }
    }

    deleteCar(car) {
        return this.http.delete(`/api/cars/${car._id}`)
            .subscribe(() => {
                this.getCars();
            });
    }

    confirmDelete(car) {
        this.messageService('Are you sure you want to delete this car?', 'Are you sure?', 'delete', car);
    }

    featureClearout() {
        this.featureData = {};
    }

    clearout() {
        this.newCar = {};
        this.newCar.engine = 'Cylinders';
        this.pageSettings.modifying = false;
        this.displayImages = false;
    }

    deleteFeature(feature){
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this feature?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '##292b2c',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value === true) {
                return this.http.delete(`/api/features/${feature._id}`)
                .subscribe(() => {
                    this.getFeatures();
                    this.addFeatureWindow = false;
                });
            }
        })
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'delete') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '##292b2c',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value === true) {
                    this.deleteCar(item);
                    this.messageService('The car has been deleted.', 'Success!', 'success');
                }
            })
        } else if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }
}
