import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'vehicle',
    template: require('./vehicle.html'),
    styles: [require('./vehicle.scss')],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    },
})
export class VehicleComponent implements OnInit, OnDestroy {
    SocketService;
    allCars = [];
    currentCar = [];
    Router;
    mainImage;
    showFull = false;
    index = 0;
    loading = false;

    messageForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]),
        message: new FormControl('', [Validators.required]),
    });

    messageFormSent = false;


    static parameters = [HttpClient, SocketService, Router, ActivatedRoute];
    constructor(http: HttpClient, socketService: SocketService, router: Router, Route: ActivatedRoute) {
        this.http = http;
        this.SocketService = socketService;
        this.Router = router;
        this.Route = Route;
        this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.getCars();
    }

    setMainImage(newPath, index){
        this.index = index;
        this.mainImage = newPath;
    }

    prevPage(){
        if(this.index == 0){
            this.index = this.currentCar.images.length;
        }
        this.mainImage = this.currentCar.images[this.index-1].path;
        this.index = this.index -1;
    }

    nextPage(){
        if(this.index == this.currentCar.images.length-1){
            this.index = -1;
        }
        this.mainImage = this.currentCar.images[this.index+1].path;
        this.index = this.index +1;
    }

    showFullPath() {
        this.showFull = true;
        console.log(this.showFull);
    }

    ngOnDestroy() {
        // this.SocketService.unsyncUpdates('thing');
    }

    getCars() {
        return this.http.get('/api/cars').subscribe((cars) => {
            cars.forEach(car => {
                car.images.forEach(image => {
                    if (image) {
                        if (image.path) {
                            image.path = this.SocketService.website + image.path;
                        }
                    }
                });
            });
            this.allCars = cars;
            this.SocketService.syncUpdates('cars', this.allCars);
            if (this.Route.snapshot.queryParams.car) {
                const carIndex = this.allCars.map((item) => item._id).indexOf(this.Route.snapshot.queryParams.car);
                if (carIndex !== -1) {
                    this.currentCar = this.allCars[carIndex];
                    this.mainImage = this.currentCar.images[0].path;
                }
            } else {
                this.Router.navigateByUrl('/inventory');
            }
        });
    }

    submitForm() {
        if (!this.loading) {
            this.loading = true;
            var id = window.setTimeout(function() {}, 0);
            while (id--) {
                window.clearTimeout(id);
            }
            this.messageFormSent = true;
            window.setTimeout(() => {
                this.messageFormSent = false;
            }, 3000);
            if (this.messageForm.valid) {
                var messageObject = {
                    to: 'carstoreutah@gmail.com',
                    from: 'Website: ' + this.messageForm.value.email,
                    status: 'sent',
                    customerName: this.messageForm.value.name,
                    customerPhone: this.messageForm.value.phone,
                    customerEmail: this.messageForm.value.email,
                    customerMessage: this.messageForm.value.message,
                    vehicle: this.currentCar
                };
                return this.http.post('/api/messages/', messageObject).subscribe(message => {
                    this.messageForm = new FormGroup({
                        name: new FormControl('', [Validators.required]),
                        phone: new FormControl('', [Validators.required]),
                        email: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]),
                        message: new FormControl('', [Validators.required]),
                    });
                    this.messageFormSent = false;
                    this.loading = false;
                    this.messageService('Your message has been sent, someone will respond soon.', 'Success!', 'success');
                });
            } else {
                this.loading = false;
                console.log('form not valid');
            }
        }
    }

    messageService(message, title?, type?, item?, message2?, title2?) {
        if (type === 'success') {
            Swal.fire({
                title: title,
                text: message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            Swal.fire(message);
        }
    }

      handleKeyboardEvents(event) {
        if(this.showFull){
            if(event.keyCode == '37'){
                this.prevPage();
            }
            if(event.keyCode == '39'){
                this.nextPage();
            }
        }
    }
}
