import {
  NgModule,
  ApplicationRef,
} from '@angular/core';
import { GetAge } from './getAge';
import { GetSafeUrl } from './getSafeUrl';
import { GetSelectedItem } from './getSelectedItem';
import { GetNormalTime } from './getNormalTime';
import { GetTimeLength } from './getTimeLength';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    GetAge,
    GetSafeUrl,
    GetSelectedItem,
    GetNormalTime,
    GetTimeLength
  ],
  exports: [
    GetAge,
    GetSafeUrl,
    GetSelectedItem,
    GetNormalTime,
    GetTimeLength
  ]
})
export class PipesModule {}