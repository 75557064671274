import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
type Thing = {
    name: string;
    info?: string;
};

@Component({
    selector: 'inventory',
    template: require('./inventory.html'),
    styles: [require('./inventory.scss')],
})
export class InventoryComponent implements OnInit, OnDestroy {
    SocketService;
    allCars = [];
    filteredCars = [];
    newSort = "Year";
    newOption = "Descending";
    sorts = ["Make", "Mileage", "Model", "Price", "Year"];
    options = ["Descending", "Ascending"];
    defaultImage = '../../assets/images/defaultimage.gif';


    Router;
    static parameters = [HttpClient, SocketService, Router, ActivatedRoute];
    constructor(http: HttpClient, socketService: SocketService, router: Router, Route: ActivatedRoute) {
        this.http = http;
        this.SocketService = socketService;
        this.Router = router;
        this.Route = Route;
        this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.getCars();
    }

    onInputChanged(inputText) {
        const modifiedAccounts = _.filter(this.allCars, (car) => {
            return car.year.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                || car.make.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                || car.model.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                || car.body.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                || car.exterior.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                // || car.vin.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                // || car.engine.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                // || car.transmission.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
                // || car.price.toLowerCase().indexOf(inputText.toLowerCase()) >= 0
        });
        this.filteredCars = modifiedAccounts;
    }

    changeOption() {
        switch (this.newSort) {
            case 'Year':
                if (this.newOption == 'Ascending') {
                    this.allCars.sort((a, b) => {
                        return a.year - b.year;
                    });
                } else {
                    this.allCars.sort((a, b) => {
                        return b.year - a.year;
                    });
                }
                break;
            case 'Price':
                if (this.newOption == 'Ascending') {
                    this.allCars.sort((a, b) => {
                        return Number(a.price.replace(/[^0-9\.]+/g, "")) - Number(b.price.replace(/[^0-9\.]+/g, ""));
                    });
                } else {
                    this.allCars.sort((a, b) => {
                        return Number(b.price.replace(/[^0-9\.]+/g, "")) - Number(a.price.replace(/[^0-9\.]+/g, ""));
                    });
                }
                break;
            case 'Mileage':
                if (this.newOption == 'Ascending') {
                    this.allCars.sort((a, b) => {
                        return a.mileage - b.mileage;
                    });
                } else {
                    this.allCars.sort((a, b) => {
                        return b.mileage - a.mileage;
                    });
                }
                break;
            case 'Make':
                if (this.newOption == 'Ascending') {
                    this.allCars.sort((a, b) => (a.make > b.make) * 2 - 1)
                } else {
                    this.allCars.sort((a, b) => (a.make < b.make) * 2 - 1)
                }
                break;

            case 'Model':
                if (this.newOption == 'Ascending') {
                    this.allCars.sort((a, b) => (a.model > b.model) * 2 - 1)
                } else {
                    this.allCars.sort((a, b) => (a.model < b.model) * 2 - 1)
                }
                break;
            default:
            // code block
        }




    }

    ngOnDestroy() {
        // this.SocketService.unsyncUpdates('thing');
    }

    getCars() {
        return this.http.get('/api/cars').subscribe((cars) => {
            cars.forEach(car => {
                car.images.forEach(image => {
                    if (image) {
                        if (image.path) {
                            image.path = this.SocketService.website + image.path;
                        }
                    }
                });
            });
            this.allCars = cars;
            this.filteredCars = cars;
            this.SocketService.syncUpdates('cars', this.allCars);
        });
    }

    readMore(car) {
        this.Router.navigateByUrl('/vehicle?car=' + car._id);
    }
}
