import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { AboutComponent } from './about.component';
import { SocketService } from '../../components/socket/socket.service';
import { UploadFileModule } from '../../components/upload-files/upload-file-component/uploadfile.module';
import { PipesModule } from '../../components/pipe/pipesModule';

export const ROUTES: Routes = [
    { path: 'about', component: AboutComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        UploadFileModule,
        PipesModule
    ],
    declarations: [
        AboutComponent,
    ],
    providers: [
        SocketService,
    ],
    exports: [
        AboutComponent,
    ],
})
export class AboutModule {}
