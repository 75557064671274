import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSelectedItem',
  pure: false
})

export class GetSelectedItem implements PipeTransform {

    constructor() {}

    transform(item: any, args?: any, allSelectedItems?: any): any {
        if (args === 'getSelected') {
            return this.getSelected(item, allSelectedItems);
        } else {
            return item;
        }
    }

    getSelected(item, allSelectedItems) {
        var itemFound = false;
        allSelectedItems.forEach(itemElement => {
            if (item._id === itemElement._id) {
                itemFound = true;
            }
        });
        return itemFound;
    }
  
}
